import React,{Fragment} from 'react';

import cmsdev1 from '../asset/image/cmsdev.png'


import { BiWorld,BiRocket } from "react-icons/bi";
import { AiOutlineShoppingCart,AiFillHtml5,AiOutlineSound,AiOutlineMail,AiOutlineCodeSandbox,AiFillIdcard } from "react-icons/ai";
import { MdSettingsApplications,MdOutlineDesignServices,MdAnimation } from "react-icons/md";
import { HiCode } from "react-icons/hi";
import { TiFlashOutline } from "react-icons/ti";
import { BsGraphUp,BsLink45Deg,BsFillPencilFill,BsBrush } from "react-icons/bs";
import { FaLeaf } from "react-icons/fa";

import Zoom from 'react-reveal/Zoom';
import Slide from 'react-reveal/Slide';


const CMSDevelopment = () => {
    return (
        <Fragment>
        <section className='graphics-design-section'>
            <Zoom>
            <div className='row'>
                <h2>Predictive Analytics and Forecasting</h2>
                <p className='des'>
                Utilizing AI and statistical modeling techniques to analyze historical data and make predictions about future outcomes. Our predictive analytics solutions help businesses identify patterns, trends, and anomalies in their data, enabling them to make informed decisions, optimize operations, and anticipate market changes.
                </p>
                {/* <h3 className='title'>We provide following Graphic Design Services:</h3> */}

                <div className='services-grid'>
                    <Slide right>
                    <div className='services-grid-inner'>
                        <div className='services-icon'>
                            <span><BsFillPencilFill/></span>
                        </div>
                        <div className='services-content'>
                            <h3>Time Series Forecasting</h3>
                            {/* <p>The logo is not just an image, it is the embodiment of an organization. Because logos are meant to represent companies and faster...</p> */}
                        </div>
                    </div>
                    </Slide>
                    <Slide left>
                    <div className='services-grid-inner'>
                        <div className='services-icon'>
                            <span><AiOutlineCodeSandbox/></span>
                        </div>
                        <div className='services-content'>
                            <h3>Predictive Maintenance and Failure Prediction</h3>
                            {/* <p>Your software box is the deciding factor for many potential customers. We help you to create professional software boxes with the...</p> */}
                        </div>
                    </div>
                    </Slide>
                    <Slide top>
                    <div className='services-grid-inner'>
                        <div className='services-icon'>
                            <span><FaLeaf/></span>
                        </div>
                        <div className='services-content'>
                            <h3>Demand Forecasting and Inventory Optimization</h3>
                            {/* <p>Greeting cards are usually given on special occasions such as birthdays, Christmas or other holidays, they are also sent on...</p> */}
                        </div>
                    </div>
                    </Slide>
                    
                    <Slide left>
                    <div className='services-grid-inner'>
                        <div className='services-icon'>
                            <span><AiFillIdcard/></span>
                        </div>
                        <div className='services-content'>
                            <h3>Customer Churn Prediction and Retention Strategies</h3>
                            {/* <p>The Business Card / Business cards many of us use it more than any other single marketing item, yet it very often demonstrates the...</p> */}
                        </div>
                    </div>
                    </Slide>
                    <Slide right>
                    <div className='services-grid-inner'>
                        <div className='services-icon'>
                            <span><BsBrush/></span>
                        </div>
                        <div className='services-content'>
                            <h3>Financial Risk Modeling and Fraud Detection</h3>
                            {/* <p>Web banners can be a form of branding for the associated website or a navigation tool for that website. A web banner is a graphical...</p> */}
                        </div>
                    </div>
                    </Slide>
                    
                </div>
            </div>
            </Zoom>
        </section>
    </Fragment>
    );
};

export default CMSDevelopment;