import React,{Fragment} from 'react';

import team2 from '../asset/image/team2.jpg'
import team9 from '../asset/image/team9.jpg'
import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';

const ManagementTeam = () => 
{
    return (
        <Fragment>
            <section className='management-team-section'>
                <div className='row'>
                    <h2>Management Team</h2>
                    <div className='management-team-grid'>
                        <Fade top>
                        <div className='col'>
                            <div className='management-team-inner-grid'>
                                <div className='management-team'>
                                    <div className='management-photo'>
                                        <img className="management-img" src={team2} />
                                        <div>
                                            <h3 className='founder-text'>Saydujjaman Shamim</h3>
                                            <h4 className='founder-text'>Founder and CEO</h4>
                                        </div>
                                    </div>
                                   
                                </div>
                            </div>
                            <p>
                                Saydujjaman is a highly accomplished professional with a Masters in Computer Application and an extensive background spanning over 20 years in the field of Information Technology. His rich and diverse experience includes significant contributions to prominent projects for Large telecom companies, the World Bank, and JICA (Japan International Cooperation Agency) as a Consultant.

                                    {/* With a profound expertise in various domains, Saydujjaman stands out as an authority in Data analytics, Data science, blockchain, AI, and Machine learning. His mastery in these cutting-edge technologies has been instrumental in shaping innovative solutions and driving success in complex IT projects.

                                    Beyond his role as a seasoned IT professional, Saydujjaman is also a dedicated educator. As a lead educator, he imparts his knowledge and skills to students, specializing in Math and Science subjects. His commitment to making education more accessible and comprehensible for students reflects his passion for empowering the next generation with the tools they need to succeed. With a profound expertise in various domains, Saydujjaman stands out as an authority in Data analytics, Data science, blockchain, AI, and Machine learning. His mastery in these cutting-edge technologies has been instrumental in shaping innovative solutions and driving success in complex IT projects.

                                    Saydujjaman's multifaceted career showcases not only his technical prowess in the IT landscape but also his commitment to education and mentorship. His journey is a testament to the impact that a seasoned professional can have on both the corporate world and the realm of education, leaving a lasting legacy of expertise and inspiration for those who follow in his footsteps. */}
                            </p>
                        </div>
                        </Fade>
                        <Fade bottom>
                        {/* <div className='col'>
                            <div className='management-team-inner-grid'>
                                <div className='management-photo'>
                                    <img className="management-img" src={team9} />
                                </div>
                                <div className='management-about'>
                                    <h3>Robiul Islam Tarafder</h3>
                                    <h4>Chairman</h4>
                                </div>
                            </div>
                            <p></p>
                        </div> */}
                        </Fade>
                    </div>
                </div>
            </section>
        </Fragment>
    );
};

export default ManagementTeam;